// Paleta de cores do Systentando
export const colors = {
  primary: {
    main: '#004f4f',
    light: '#006d6d',
    dark: '#003535',
    contrastText: '#ffffff'
  },
  accent: {
    main: '#ff7d50',
    light: '#ff9b78',
    dark: '#dd5c30',
    contrastText: '#ffffff'
  },
  dark: {
    background: '#121212',
    card: '#1e1e1e',
    border: '#333333',
    text: '#ffffff',
    subtext: '#aaaaaa'
  },
  systentando: {
    background: '#004f4f',
    card: '#1e1e1e',
    border: '#333333',
    text: '#ffffff',
    subtext: '#aaaaaa'
  },
  feedback: {
    success: '#00b894',
    warning: '#fdcb6e',
    error: '#d63031',
    info: '#0984e3'
  },
  categories: {
    health: '#00b894',     // Saúde
    sleep: '#9b59b6',      // Sono
    personal: '#e84393',   // Desenvolvimento pessoal
    work: '#3498db',       // Trabalho
    finance: '#f39c12'     // Finanças
  }
};
